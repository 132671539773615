<template>
  <teleport to='#app'>
    <div class="modal-container modal-container_top" v-if="isOpen"></div>
    <div
      class="modal modal_top fade show"
      role="dialog"
      aria-hidden="true"
      :style="isOpen ? 'display: block' : 'display: none'"
    >
    <slot :close="close"></slot>
    </div>
  </teleport>
  <slot name="trigger" :open="open"></slot>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'ModalWrapper',
  setup() {
    const isOpen = ref(false)
    const open = () => {
      isOpen.value = true
    }
    const close = () => {
      isOpen.value = false
    }
    return {
      isOpen,
      open,
      close,
    }
  },
}
</script>
